import {
  AppBar,
  Card,
  Grid,
  MuiThemeProvider,
  Tab,
  Tabs,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import React, { useState } from "react";
import { appTheme } from "../riskAssessment/brmDetaails";
import TabPanel from "../../UI/tabPanel/tabPanel";

import CustomButton from "../../UI/button/button";
import ShareIcon from "../../../images/share-2.svg";
import ExportIcon from "../../../images/export.svg";
import SurveyDocuments from "./surveyDocuments";
import CompanyDocuments from "./companyDocuments";

export const appbarStyles = makeStyles((theme) => ({
  AppBar: { boxShadow: "none" },
  tabLabel: {
    textTransform: "capitalize",
    fontSize: 14,
    fontFamily: "Poppins",
  },
  tab: {
    fontSize: 12,
  },
}));
function Documents({
  showPeerDocs,
  companyName,
  setShowpeerDocs,
  brmData,
  getPublicReports,
  login,
  ...props
}) {
  // TABS DATA FROM REDUX
  const { riskEvaluator } = props;

  const [profileTabNo, setProfileTabNo] = useState(0);
  const classes = appbarStyles();

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            Documents
          </Typography>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {/* <CustomButton
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            startIcon={<img src={ExportIcon} alt="share_icon" />}
          >
            Export
          </CustomButton>
          <CustomButton
            variant="text"
            color="primary"
            style={{ textTransform: "capitalize" }}
            startIcon={<img src={ShareIcon} alt="share_icon" />}
          >
            Share
          </CustomButton> */}

          {/* <InfoOutlinedIcon
            style={{ color: "#3374b9", marginRight: "12px", cursor: "pointer" }}
          />
          <ShareOutlinedIcon
            style={{ color: "#3374b9", marginRight: "5px", cursor: "pointer" }}
          /> */}
        </div>
      </div>

      <div>
        <MuiThemeProvider theme={appTheme}>
          <AppBar position="static" color="inherit" className={classes.AppBar}>
            <Tabs
              value={profileTabNo}
              onChange={(e, newVal) => {
                setProfileTabNo(newVal);
              }}
              // onChange={handleTabChange}
            >
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Company Documents</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}>Dataform Documents</span>
                }
              />

              {/* <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>ESG Insights</span>}
              /> */}
              {/* <Tab
                disableRipple
                className={classes.tab}
                label={
                  <span className={classes.tabLabel}> Dimension Chat</span>
                }
              />
              <Tab
                disableRipple
                className={classes.tab}
                label={<span className={classes.tabLabel}>Overall</span>}
              /> */}
            </Tabs>
          </AppBar>
        </MuiThemeProvider>
        <TabPanel value={profileTabNo} index={0}>
          <div
            className="scroll"
            style={{ height: "55vh", marginTop: "10px", paddingBottom: 20 }}
          >
            <CompanyDocuments
              riskEvaluator={riskEvaluator}
              showPeerDocs={showPeerDocs}
              companyName={companyName}
              setShowpeerDocs={setShowpeerDocs}
              brmData={brmData}
              login={login}
              getPublicReports={getPublicReports}
              {...props}
            />
          </div>
        </TabPanel>
        <TabPanel value={profileTabNo} index={1}>
          <SurveyDocuments />
        </TabPanel>
        {/* <TabPanel value={profileTabNo} index={2}>
          <OverallProfile {...props} />
          
        </TabPanel> */}
      </div>
    </div>
  );
}

export default Documents;
