import UNIVERSAL from "../../../config";
import {
  SET_ADD_STAKEHOLDER,
  SET_ADD_VENDOR,
  SET_BANK_SHARED_SURVEY,
  SET_COMPANY_DOCUMENTS,
  SET_KYP_COMPANIES,
  SET_KYP_DATA,
  SET_LINKAGE_SURVEYS,
  SET_LINKAGE_SURVEY_QUESTIONS,
  SET_LINKAGE_SURVEY_SECTIONS,
  SET_MEMBERS,
  SET_ORGANIZATIONS_LIST,
  SET_ORGANIZATION_DETAILS,
  SET_REQUESTED_FILES,
  SET_SEARCH_SURVEYS_BY_NAME,
  SET_SEARCH_VENDORS_BY_NAME,
  SET_SHOW_SELECTED_SURVEY,
  SET_SHOW_SURVEYS,
  SET_SURVEY_BANKS_LIST,
  SET_TASK_BANKS_LIST,
  SET_UPDATE_ORGS_LIST,
  SET_VA_BANKS_LIST,
  SET_VENDORS_BY_ISIN,
  SET_VENDOR_BASIC_DETAILS,
  SET_VENDOR_DASHBOARD_DATA,
  SET_VENDOR_IQ_DATA,
  SET_VENDOR_IQ_SEARCH_COMPANIES,
  SET_VENDOR_MATERIALITY_SCORES,
} from "../../../constants/brmConstants";
import {
  ADD_NEW_STAKEHOLDERS,
  CREATE_VENDOR,
  GET_ALL_STAKEHOLDERS,
  GET_ALL_VENDORS,
  GET_BANK_ADMIN_SINGLE_SURVEY,
  GET_BANK_ADMIN_SURVEYS,
  GET_SINGLE_SURVEY_QUESTION_COUNT,
  GET_VENDOR_BASIC_DETAILS,
  GET_VENDOR_NAME_FOR_VA,
  GQL_CREATE_ROI,
  GQL_GET_KYP_COMPANIES,
  GQL_GET_KYP_DATA,
  GQL_GET_LINKAGE_SURVEY,
  GQL_GET_LINKAGE_SURVEY_QUESTIONS,
  GQL_GET_LINKAGE_SURVEY_SECTIONS,
  GQL_GET_SURVEYS_BY_NAME,
  GQL_GET_VENDORS_BY_ISIN,
  GQL_GET_VENDORS_BY_NAME,
  GQL_GET_VENDOR_IQ_COMPANIES,
  GQL_GET_VENDOR_IQ_DATA,
  GQL_GET_VENDOR_MATERIALITY_SCORE,
  GQL_GET_VENDOR_UPLOADED_FILES,
  GQL_GET_VENDOR_UPLOADED_FILES_IN_SURVEY,
  GQL_GET_VENDOR_UPLOADED_FILES_STATUS,
  GQL_SEND_TO_MAGPIE,
  GQL_SET_KYP_ID,
  GQL_SET_VENDOR_IQ_ID,
  GQL_SUBMIT_LINKAGE_QUESTIONS,
  GQL_UPDATE_SURVEY,
  REQUEST_FILES_STAKEHOLDERS,
  SHARE_BANK_ADMIN_SURVEY,
  UPDATE_VENDOR,
} from "../../graphql/gql_BankAdminQueries";
import { client } from "../../store/store";
import { set_loader, unset_loader } from "../loader/loader_action";
import { checkIsUnAuth, handleUnauthInterceptor } from "../login/loginAction";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";
import {
  ASSIGN_SURVEY,
  GET_ASSIGNED_STATUS,
  GET_BANK_TASKS,
  GET_QUESTIONS_WITH_STATUS,
  GET_REQUESTED_FILES,
  GET_SHARED_SURVEY,
  GET_SURVEY_BANKS_LIST,
  GET_TASK_BANKS_LIST,
  GET_VA_BANKS_LIST,
  MARK_SURVEY_COMPLETE,
  UPDATE_QUESTION_STATUS,
} from "../../graphql/gql_VendorAdminQueries";
import {
  SET_COMPLETED_SEC_QUES,
  SET_SELECTED_VA_SURVEY,
  SET_STEP2_SEC_QUESTIONS,
  SET_STEP2_SECTIONS,
  SET_STEP3_SEC_QUESTIONS,
  SET_STEP3_SECTIONS,
  SET_VENDOR_UPLOADED_FILES,
  SET_VENDOR_UPLOADED_FILES_IN_SURVEY,
  SET_VENDOR_UPLOADED_FILES_STATUS,
} from "../../../constants/vendorConstants";
import {
  GET_AUDIT_QUESTIONS,
  GET_AUDIT_SECTIONS,
  GET_COMPLETED_SECTION_QUESTIONS,
  GET_SURVEY_SECTIONS,
  GET_VA_SECTION_QUESTIONS,
} from "../../graphql/gql_SurveyQueries";
import { gql_get_survey_details } from "../riskdashboard/riskDashboardActions";
import { GET_VENDOR_DASHBOARD_QUERY } from "../../graphql/gql_VendorDashboardQueries";
import {
  GQL_GET_ESG_RISK,
  GQL_GET_VENDOR_RISK,
  GQL_UPDATE_ESG_RISK,
  GQL_UPDATE_VENDOR_RISK,
} from "../../graphql/gql_vendorRisk";
import { SET_ESG_RISK, SET_VENDOR_RISK } from "../../../constants/vendorRisk";
import { DEFAULT_VENDOR_USER_TOKEN } from "../../../constants/esgDiagnosisConstant";
import { Snackbar } from "@material-ui/core";

export function createVendorOrganization(
  token,
  bankId,
  name,
  isin,
  year_of_assessment,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors,
  rowsPerPage,
  revenue,
  turnover,
  country
) {
  return (dispatch) => {
    dispatch(set_loader());
    var sectorData = [],
      sectorData2 = [];

    sectors &&
      sectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector &&
          sector.industry_data.map((industry, index2) => {
            if (industry.isSelected) {
              sectorData[index].industry.push(industry._id);
            }
            return true;
          });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });

    console.log(
      "create api ",
      // token,
      // bankId,
      // name,
      // isin,
      // year_of_assessment,
      // logo,
      // sectors,
      // operation,
      // no_of_employees,
      // address,
      // masterSectors,
      // sectorData2,
      country,
      revenue,
      turnover
    );
    // const dummy = {
    //   "user-token":
    //     "eyJhbGciOiJIUzI1NiJ9.c2FhZEBhcHBzdG9uZS5pbg.HjRS3-CblA1TKudOd0Hw3oaofnSiwuJIZQiVqkGVHwE",
    //   bank_id: "63da5f8a6369492c38bedef1",
    //   name: "vendor 2",
    //   isin: "ISIN002",
    //   year_of_assessment: "2023-2024",
    //   sectors: [
    //     {
    //       sector: "6197687b956d954194412524",
    //       industry: ["6197687b956d95419441254d"],
    //     },
    //   ],
    //   operation: "ops",
    //   no_of_employees: "251-1000",
    //   address: [
    //     {
    //       landmark: "",
    //       state: "Odisha",
    //       companyAddress: "12 fv",
    //       landMark: "land mark",
    //       pinCode: "560061",
    //       country: "India",
    //     },
    //   ],
    //   revenue: [
    //     {
    //       revenue: "27",
    //       year: "2023-2024",
    //       currency: "USD",
    //       figures: "Million",
    //     },
    //   ],
    //   turnover: [],
    //   country: "IN",
    // };
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      name: name,
      isin,
      year_of_assessment,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address,
      revenue: revenue || [],
      turnover: turnover || [],
      country,
    });

    var formData = new FormData();
    formData.append("data", data);
    formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/vendor/create_vendor_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(viewVendorOrganization(token, bankId, rowsPerPage, 0));
          // dispatch(setOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addVendorToList(
  name,
  type,
  website,
  logo,
  sectors,
  userName,
  userDesignation,
  operation,
  no_of_employees,
  address
) {
  return (dispatch) => {
    dispatch(set_loader());

    const currentDate = new Date();

    const data = {
      _id: `${currentDate.getTime()}`,
      bank_id: "63da5f8a6369492c38bedef1",
      name: name,
      type: type,
      website: website,
      logo: logo,
      country: "IN",
      userName: userName,
      userDesignation: userDesignation,
      sectors: sectors,
      isin: "ISIN001",
      year_of_assessment: "2023-2024",
      operation: operation,
      no_of_employees: no_of_employees,
      status: "incomplete",
      address: address || [],
      created_by: "63e20b1959c56d85c5910042",
      created_at: currentDate.toJSON(),
      modified_by: "63ee1726c317ed7d780980a4",
      modified_at: currentDate.toJSON(),
    };

    dispatch(addNewVendor(data));

    dispatch(unset_loader());
  };
}

export function selectVendor(data) {
  return (dispatch) => {
    dispatch(set_loader());

    dispatch(setVendorOrganizationDetails(data));

    dispatch(unset_loader());
  };
}

export function addCompanyDetails(data) {
  return (dispatch) => {
    dispatch(set_loader());

    dispatch(setCompanyDocuments(data));

    dispatch(unset_loader());
  };
}

export function updateVendorOrg(data) {
  return (dispatch, getState) => {
    dispatch(set_loader());

    const allOrgs = getState().brmData.organizationsList;
    const selectedOrg = getState().brmData.organizationDetails[0];

    // Find the org where _id matches data._id
    const foundIndex = allOrgs.findIndex((org) => org._id === data._id);

    if (foundIndex !== -1) {
      const updatedOrgs = [
        ...allOrgs.slice(0, foundIndex),
        data,
        ...allOrgs.slice(foundIndex + 1),
      ];

      // Updating the state with the new array of organizations
      dispatch(updateOrganizationsList(updatedOrgs));

      // update selected org
      if (selectedOrg._id === data._id) {
        dispatch(selectVendor([data]));
      }
    } else {
      console.log("Organization not found");
    }

    dispatch(unset_loader());
  };
}

export function updateVendorOrganization(
  token,
  organization_id,
  name,
  isin,
  year_of_assessment,
  logo,
  sectors,
  operation,
  no_of_employees,
  address,
  masterSectors,
  revenue,
  turnover,
  country
) {
  return (dispatch) => {
    dispatch(set_loader());

    var sectorData = [],
      sectorData2 = [];

    sectors &&
      sectors.map((sector, index) => {
        sectorData.push({
          sector: sector._id,
          industry: [],
        });

        sector &&
          sector.industry_data.map((industry, index2) => {
            if (industry.isSelected) {
              sectorData[index].industry.push(industry._id);
            }
            return true;
          });
        return true;
      });

    sectorData.map((sector, index) => {
      if (sector.industry.length > 0) {
        sectorData2.push(sector);
      }
      return true;
    });
    // console.log({
    // "user-token": token,
    // organization_id,
    // name: name,
    // isin,
    // year_of_assessment,
    // sectorData2,
    // logo,
    // operation,
    // no_of_employees,
    // address: address[0],
    // });
    const data = encrypt({
      "user-token": token,
      organization_id,
      name: name,
      isin,
      year_of_assessment,
      sectors: sectorData2,
      operation,
      no_of_employees,
      address: address[0],
      country,
      revenue,
      turnover,
    });
    var formData = new FormData();

    formData.append("data", data);
    logo && formData.append("logo", logo);
    return fetch(UNIVERSAL.BASEURL + "/vendor/update_vendor_organization", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "content-type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewVendorDetailsOrganization(token, organization_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addNewVendor(payload) {
  return {
    type: SET_ADD_VENDOR,
    payload: payload,
  };
}
export function setVendorOrganizationDetails(payload) {
  return {
    type: SET_ORGANIZATION_DETAILS,
    payload: payload,
  };
}

export function updateOrganizationsList(payload) {
  return {
    type: SET_UPDATE_ORGS_LIST,
    payload: payload,
  };
}

export function setCompanyDocuments(payload) {
  return {
    type: SET_COMPANY_DOCUMENTS,
    payload: payload,
  };
}

const setStakeholders = (members) => {
  return { type: SET_MEMBERS, payload: members };
};

const addStakeholders = (name, email, vendorId, category) => {
  return {
    type: SET_ADD_STAKEHOLDER,
    payload: { name, email, vendorId, category },
  };
};

export function viewVendorDetailsOrganization(token, organizationId) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      organization_id: organizationId,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/vendor/view_vendor_organization_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({
          data: data,
        }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setVendorOrganizationDetails(responseJson.result));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

const setOrganizationsList = (payload, total) => {
  return {
    type: SET_ORGANIZATIONS_LIST,
    payload,
    total,
  };
};

export function viewVendorOrganization(
  token,
  bankId,
  limit,
  starting_after,
  keyword
) {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      bank_id: bankId,
      limit,
      starting_after,
      keyword: keyword || "",
    });
    return fetch(UNIVERSAL.BASEURL + "/vendor/view_vendor_organizations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
      },
      body: JSON.stringify({
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setOrganizationsList(responseJson.result, responseJson.total)
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// -------------------- REST Actions --------------------
export async function upload_create_vendor_img(file, id, token) {
  const formData = new FormData();
  formData.append("logo", file);
  // formData.append("file", file);

  console.log(formData);

  try {
    const { data } = await fetch(
      UNIVERSAL.BASEURL + `/vendors/upload/logo/${id}`,
      {
        method: "POST",
        body: formData,
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log("Logo uploaded successfully", data);
    return data;
  } catch (error) {
    throw error.message;
  }
}

// -------------------- GQL Actions --------------------
export function gql_get_vendors_list(limit, page, type) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GET_ALL_VENDORS,
        variables: { pagination: { limit, page }, type },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor({ query: GET_ALL_VENDORS }, "query")
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(
        setOrganizationsList(
          result.data.vendorsList.vendors,
          result.data.vendorsList.count
        )
      );

      // console.log(result.data);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_create_vendor(
  name,
  type,
  website,
  logo,
  sectorObj,
  isin,
  operation,
  employeesCount,
  address,
  token,
  revenue,
  turnover,
  setVendorId,
  setIsAddCompanyDrawer,
  setActiveSteps
) {
  const { country, landmark, street, pinCode, state } = address;
  const { sector, industry } = sectorObj;
  return async (dispatch) => {
    dispatch(set_loader());
    const newAddress = {
      country,
      landmark,
      location: street,
      pincode: parseInt(pinCode),
      state,
    };
    const mutationQuery = {
      mutation: CREATE_VENDOR,
      variables: {
        name,
        type,
        website,
        isin,
        operation,
        employeesCount,
        sector: {
          sector,
          industry,
        },
        address: newAddress,
        revenue,
        turnover,
      },
    };
    try {
      const result = await client.mutate(mutationQuery);
      // unauth interceptor
      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(mutationQuery, "mutate"));
        } else {
          dispatch(set_snack_bar(true, result.errors[0]?.message));
          setIsAddCompanyDrawer(false);
          setActiveSteps(0);
          throw result.errors[0];
        }
      }

      const logoData = await upload_create_vendor_img(
        logo,
        result?.data?.createVendor?._id,
        token
      );

      // if (result?.data?.createVendor?._id) {
      //   dispatch(
      //     gql_create_roi(
      //       allResponseArray,
      //       result?.data?.createVendor?._id,
      //       "createVendor"
      //     )
      //   );
      // }

      setVendorId(result?.data?.createVendor?._id);

      dispatch(
        set_snack_bar(true, "Vendor has been created, continue to RT details")
      );
    } catch (err) {
      console.log(err);
    }
    // dispatch(unset_loader());
  };
}

export function gql_create_roi(answers, vendorId, isFrom) {
  return async (dispatch) => {
    dispatch(set_loader());

    const mutationQuery = {
      mutation: GQL_CREATE_ROI,
      variables: { submitROIDataInput: { answers, vendorId } },
    };
    try {
      const result = await client.mutate(mutationQuery);
      // unauth interceptor
      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(mutationQuery, "mutate"));
        } else {
          dispatch(set_snack_bar(true, result.errors[0]?.message));
          throw result.errors[0];
        }
      }
      if (isFrom !== "createVendor") {
        dispatch(set_snack_bar(true, "ROI has been updated successfully"));
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_all_stakeholders(vendorId, type) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GET_ALL_STAKEHOLDERS,
        variables: { vendorId, type },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GET_ALL_STAKEHOLDERS, variables: { vendorId } },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setStakeholders(result.data.stakeholderList));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_add_new_stakeholder(
  email,
  name,
  vendorId,
  category,
  designation
) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.mutate({
        mutation: ADD_NEW_STAKEHOLDERS,
        variables: { email, name, vendorId, category, designation },
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                mutate: ADD_NEW_STAKEHOLDERS,
                variables: { email, name, vendorId, category, designation },
              },
              "mutation"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result.data.addStakeholder.message));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export const setVendorBasicDetails = (payload) => {
  return { type: SET_VENDOR_BASIC_DETAILS, payload };
};

export const gql_get_vendor_basic_details = (vendorId) => {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GET_VENDOR_BASIC_DETAILS,
        variables: { vendorId },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GET_VENDOR_BASIC_DETAILS, variables: { vendorId } },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setVendorBasicDetails(result.data.vendor));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
};

export const gql_get_vendor_name_for_va = (vendorId) => {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.query({
        query: GET_VENDOR_NAME_FOR_VA,
        variables: { vendorId },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              { query: GET_VENDOR_BASIC_DETAILS, variables: { vendorId } },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      await dispatch(setVendorBasicDetails(result.data.vendor));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
};

export function gql_request_files_stakeholder(docs, stakeholders, vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const result = await client.mutate({
        mutation: REQUEST_FILES_STAKEHOLDERS,
        variables: { docs, stakeholders, vendorId },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                mutate: REQUEST_FILES_STAKEHOLDERS,
                variables: { docs, stakeholders, vendorId },
              },
              "mutation"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, result.data.requestFiles.message));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export const gql_update_vendor = (
  id,
  address,
  employeesCount,
  logo,
  name,
  website,
  sectorObj,
  operation,
  revenue,
  turnover
) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      if (!Array.isArray(logo)) {
        // Call get_logo function to get a new object for the logo
        logo = await upload_create_vendor_img(logo, id);
      }

      const { sector, industry } = sectorObj;

      const vendorMutate = {
        mutation: UPDATE_VENDOR,
        variables: {
          id,
          updateVendorInput: {
            address,
            employeesCount,
            logo,
            name,
            website,
            sector: {
              sector,
              industry,
            },
            operation,
            revenue,
            turnover,
          },
        },
      };

      const result = await client.mutate(vendorMutate);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(vendorMutate, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      console.log(result);

      await dispatch(gql_get_vendor_basic_details(id));

      dispatch(set_snack_bar(false, "Profile Updated"));
    } catch (error) {
      console.log(error);
      dispatch(set_snack_bar(false, error.message));
    }

    dispatch(unset_loader());
  };
};

const setShowSurveys = (surveys) => {
  return { type: SET_SHOW_SURVEYS, payload: surveys };
};

export const gql_get_bank_admin_surveys = (vendorId, type) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const result = await client.query({
        query: GET_BANK_ADMIN_SURVEYS,
        variables: { vendorId, type },
        errorPolicy: "all",
      });

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(
            handleUnauthInterceptor(
              {
                query: GET_BANK_ADMIN_SURVEYS,
                variables: { vendorId },
              },
              "query"
            )
          );
        } else {
          throw result.errors[0];
        }
      }

      // filter out published versions only
      const filteredSurvey = result.data?.surveys?.surveys?.filter(
        (e) => e?.publishedVersion !== null
      );

      if (result.data?.surveys?.surveys?.length === 0) {
        await dispatch(setShowSurveys([]));
      } else {
        await dispatch(setShowSurveys(filteredSurvey));
      }
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
};

export const setShowSelectedSurvey = (survey) => {
  return { type: SET_SHOW_SELECTED_SURVEY, payload: survey };
};

export const gql_get_bank_admin_single_survey = (id, version, vendorId) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const surveyQuery = {
        query: GET_BANK_ADMIN_SINGLE_SURVEY,
        variables: { id: String(id), version, vendorId },
      };

      const result = await client.query(surveyQuery);

      if (result.error) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(surveyQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);

      dispatch(setShowSelectedSurvey(result.data.survey));
    } catch (err) {
      // await dispatch(set_snack_bar(true, err.message));
    }

    dispatch(unset_loader());
  };
};

export const gql_get_single_survey_question_count = (id, version, vendorId) => {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const surveyQuery = {
        query: GET_SINGLE_SURVEY_QUESTION_COUNT,
        variables: { id: String(id), version },
      };

      const result = await client.query(surveyQuery);

      if (result.error) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(surveyQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result);

      dispatch(setShowSelectedSurvey(result.data.survey));
    } catch (err) {
      // await dispatch(set_snack_bar(true, err.message));
    }

    dispatch(unset_loader());
  };
};

export const gql_share_bank_admin_survey = (
  stakeholdersIds,
  bankId,
  noOfSurveyReminders,
  surveyDate,
  surveyId,
  vendorId,
  frequencyTypeName,
  version,
  totalReshares
) => {
  return async (dispatch) => {
    try {
      dispatch(set_loader());

      const shareSurveyMutate = {
        mutation: SHARE_BANK_ADMIN_SURVEY,
        variables: {
          stakeholdersIds,
          bankId,
          noOfSurveyReminders,
          surveyDate,
          surveyId,
          vendorId,
          frequencyTypeName,
          version,
          totalReshares,
        },
      };

      const result = await client.mutate(shareSurveyMutate);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(shareSurveyMutate, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log("1092", result);

      dispatch(set_snack_bar(true, result.data.shareSurvey.message));
    } catch (err) {
      dispatch(set_snack_bar(true, "Faild to share the survey!"));
    }

    dispatch(unset_loader());
  };
};

// To get bank list for VA survey and task

const setVaBanksList = (banksList) => {
  return { type: SET_VA_BANKS_LIST, payload: banksList };
};

export function gql_get_va_banks_list(type, limit, page, search) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const banksQuery = {
        query: GET_VA_BANKS_LIST,
        variables: { type, pagination: { limit, page }, search },
      };

      const result = await client.query(banksQuery);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(banksQuery, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setVaBanksList(result?.data?.Banks));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

// const setTaskBanksList = (banksList) => {
//   return { type: SET_TASK_BANKS_LIST, payload: banksList };
// };

// export function gql_get_task_banks_list() {
//   return async (dispatch) => {
//     dispatch(set_loader());

//     try {
//       const banksQuery = {
//         query: GET_TASK_BANKS_LIST,
//       };

//       const result = await client.query(banksQuery);

//       if (result.errors) {
//         const isUnAuth = checkIsUnAuth(result.errors[0]);

//         if (isUnAuth) {
//           dispatch(handleUnauthInterceptor(banksQuery, "query"));
//         } else {
//           throw result.errors[0];
//         }
//       }

//       dispatch(
//         setTaskBanksList(result?.data?.getBanksWhoseSurveysWereAssigned)
//       );
//     } catch (err) {
//       console.log(err);
//     }
//     dispatch(unset_loader());
//   };
// }

const setSharedSurvey = (survey) => {
  return { type: SET_BANK_SHARED_SURVEY, payload: survey };
};

export const setSelectedVASurvey = (survey) => {
  return {
    type: SET_SELECTED_VA_SURVEY,
    payload: survey,
  };
};

export const setStep2Sections = (survey) => {
  return {
    type: SET_STEP2_SECTIONS,
    payload: survey,
  };
};

export const setStep2SecQues = (survey) => {
  return {
    type: SET_STEP2_SEC_QUESTIONS,
    payload: survey,
  };
};

export const setStep3Sections = (sections) => {
  return {
    type: SET_STEP3_SECTIONS,
    payload: sections,
  };
};

export const setStep3SecQues = (questions) => {
  return {
    type: SET_STEP3_SEC_QUESTIONS,
    payload: questions,
  };
};

export const setCompletedSecQues = (array) => {
  return {
    type: SET_COMPLETED_SEC_QUES,
    payload: array,
  };
};

// -------------------------------------------------------

export function gql_get_va_survey_sections(selectedSurvey) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const queryObj = {
        query: GET_SURVEY_SECTIONS,
        variables: {
          id: selectedSurvey?.surveyId,
          version: selectedSurvey?.latestVersion,
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setStep2Sections(result?.data?.survey?.sections?.sections));

      // console.log(result.data);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_va_section_questions(
  sectionId,
  frequencyId,
  setSectionQuestions,
  bankId,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    console.log(bankId, vendorId);
    try {
      const queryObj = {
        query: GET_VA_SECTION_QUESTIONS,
        variables: { sectionId, frequencyId, bankId, vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // console.log(result.data?.getQuestionsWithStatus?.questions);

      await dispatch(
        setStep2SecQues(result.data?.getQuestionsWithStatus?.questions)
      );

      setSectionQuestions(result?.data?.getQuestionsWithStatus);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_step3_survey_sections(frequencyId, surveyId, vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const queryObj = {
        query: GET_AUDIT_SECTIONS,
        variables: {
          getAssignedSectionsInput: {
            frequencyId,
            surveyId,
            vendorId,
          },
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setStep3Sections(result?.data?.getAssignedSections[0]));

      // console.log(result.data);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_step3_section_questions(
  frequencyId,
  sectionId,
  setSectionQuestions,
  bankId,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_AUDIT_QUESTIONS,
        variables: {
          getAssignedQuestionsInput: {
            frequencyId,
            sectionId,
            bankId,
            vendorId,
          },
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setStep3SecQues(result.data?.getAssignedQuestions[0]));
      setSectionQuestions(result.data?.getAssignedQuestions[0]);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_completed_section_questions(
  surveyId,
  frequencyId,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());
    try {
      const queryObj = {
        query: GET_COMPLETED_SECTION_QUESTIONS,
        variables: {
          getCompletedQuestionsInput: {
            surveyId,
            frequencyId,
            vendorId,
          },
        },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setCompletedSecQues(result.data?.getCompletedQuestions));
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_get_shared_survey(id, userType) {
  return async (dispatch) => {
    dispatch(set_loader());

    let bankId;
    let vendorId;

    if (userType === "BSA") {
      vendorId = id;
    } else {
      bankId = id;
    }

    try {
      const sharedSurvey = {
        query: GET_SHARED_SURVEY,
        variables: userType === "BSA" ? { vendorId } : { bankId },
      };

      const result = await client.query(sharedSurvey);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(sharedSurvey, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setSharedSurvey(result?.data?.getSharedSurveys));
      // console.log(result);
    } catch (err) {
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_assign_survey(assignSurveyInput) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutateObj = {
        mutation: ASSIGN_SURVEY,
        variables: { assignSurveyInput },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Survey Assigned"));

      // console.log(result);
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Assign Survey"));
      console.log(err);
    }
    dispatch(unset_loader());
  };
}

export function gql_mark_survey_complete(
  surveyId,
  frequencyId,
  bankId,
  setActiveStep,
  vendorId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    console.log(surveyId);

    try {
      const mutateObj = {
        mutation: MARK_SURVEY_COMPLETE,
        variables: {
          markSurveyAsCompleteInput: {
            frequencyId,
            surveyId,
            bankId,
            vendorId,
          },
        },
      };

      const result = await client.mutate(mutateObj);

      console.log(result);
      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          dispatch(set_snack_bar(true, "Please complete the survey!"));
        }
      } else {
        dispatch(set_snack_bar(true, "Survey Complete"));
      }

      if (result?.errors && result?.errors?.length > 0) {
        setActiveStep((prevActiveStep) => prevActiveStep);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Submit Survey"));
      console.log(err);
      return false;
    }
    dispatch(unset_loader());
  };
}

export function gql_update_question_status(
  frequencyId,
  questionId,
  status,
  vendorId,
  bankId
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutateObj = {
        mutation: UPDATE_QUESTION_STATUS,
        variables: {
          updateSubmittedQuestionStatusInput: {
            frequencyId,
            questionId,
            status,
            vendorId,
            bankId,
          },
        },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Question Status Updated"));
      // console.log(result);
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Assign Survey"));
      console.log(err);
      dispatch(unset_loader());
      return false;
    }
    dispatch(unset_loader());
  };
}

export function gql_get_assigned_status(
  getQuestionAssignedStatusInput,
  setQuesAssignedData
) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GET_ASSIGNED_STATUS,
        variables: { getQuestionAssignedStatusInput },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // managing state

      setQuesAssignedData(result.data?.getAssignedStatus);

      // console.log(result);
    } catch (err) {
      console.log(err);
      setQuesAssignedData(null);
      dispatch(set_snack_bar(true, "This question is not yet assigned"));
    }
    dispatch(unset_loader());
  };
}

const setRequestedFiles = (data) => {
  return { type: SET_REQUESTED_FILES, payload: data };
};

export function gql_get_requested_files(bankId) {
  return async (dispatch) => {
    dispatch(set_loader());

    const timestamp = new Date();

    try {
      const queryObj = {
        query: GET_REQUESTED_FILES,
        variables: { bankId, timestamp },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(setRequestedFiles(result.data?.getRequestedFiles));
    } catch (err) {
      console.log(err.message);
    }

    dispatch(unset_loader());
  };
}

export function gql_update_survey(updateSurveyInput) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutateObj = {
        mutation: GQL_UPDATE_SURVEY,
        variables: {
          updateSurveyInput,
        },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Survey Updated"));
      // console.log(result);
      dispatch(gql_get_survey_details(result?.data?.updateSurvey?._id));
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Update Survey"));
      console.log(err);
      return false;
    }
    dispatch(unset_loader());
  };
}

export function gql_update_vendor_risk(comment, risk, vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutateObj = {
        mutation: GQL_UPDATE_VENDOR_RISK,
        variables: {
          comment,
          risk,
          vendorId,
        },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Updated"));
      // console.log(result);
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Update "));
      console.log(err);
      return false;
    }
    dispatch(unset_loader());
  };
}

export function gql_get_vendor_risk(vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDOR_RISK,
        variables: { vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({ type: SET_VENDOR_RISK, payload: result?.data?.getVendorRisk });
    } catch (err) {
      console.log(err.message);
    }

    dispatch(unset_loader());
  };
}

export function gql_update_esg_risk(comment, risk, vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const mutateObj = {
        mutation: GQL_UPDATE_ESG_RISK,
        variables: {
          comment,
          risk,
          vendorId,
        },
      };

      const result = await client.mutate(mutateObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          dispatch(handleUnauthInterceptor(mutateObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Updated"));
      // console.log(result);
    } catch (err) {
      dispatch(set_snack_bar(true, "Failed to Update "));
      console.log(err);
      return false;
    }
    dispatch(unset_loader());
  };
}

export function gql_get_esg_risk(vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_ESG_RISK,
        variables: { vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);

        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({ type: SET_ESG_RISK, payload: result?.data?.getEsgRisk });
    } catch (err) {
      console.log(err.message);
    }

    dispatch(unset_loader());
  };
}

export function rest_get_vendor_uploaded_files(token, isin) {
  const data = encrypt({
    "user-token": DEFAULT_VENDOR_USER_TOKEN,
    isin,
  });

  return (dispatch) => {
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.ESG_DIAGNOSIS_REST + "/organization/get_company_documents",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        // dispatch(getPublicReports(token, organization_id));
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        dispatch({
          type: SET_VENDOR_UPLOADED_FILES,
          payload: responseJson?.result,
        });
      })
      .catch((error) => {
        // console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function rest_get_vendor_uploaded_files_in_survey(vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDOR_UPLOADED_FILES_IN_SURVEY,
        variables: { vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_VENDOR_UPLOADED_FILES_IN_SURVEY,
        payload: result.data.surveyDocs,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

// export function gql_get_vendor_uploaded_files(vendorId) {
//   return async (dispatch) => {
//     dispatch(set_loader());

//     try {
//       const queryObj = {
//         query: GQL_GET_VENDOR_UPLOADED_FILES,
//         variables: { vendorId },
//       };

//       const result = await client.query(queryObj);

//       if (result.errors) {
//         const isUnAuth = checkIsUnAuth(result.errors[0]);

//         if (isUnAuth) {
//           await dispatch(handleUnauthInterceptor(queryObj, "query"));
//         } else {
//           throw result.errors[0];
//         }
//       }

//       dispatch({
//         type: SET_VENDOR_UPLOADED_FILES,
//         payload: result?.data?.vendorFiles,
//       });
//     } catch (err) {
//       console.log(err.message);
//     }

//     dispatch(unset_loader());
//   };
// }

// export function gql_get_vendor_uploaded_files_status(vendorId) {
//   return async (dispatch) => {
//     dispatch(set_loader());

//     try {
//       const queryObj = {
//         query: GQL_GET_VENDOR_UPLOADED_FILES_STATUS,
//         variables: { vendorId },
//       };

//       const result = await client.query(queryObj);

//       if (result.errors) {
//         const isUnAuth = checkIsUnAuth(result.errors[0]);

//         if (isUnAuth) {
//           await dispatch(handleUnauthInterceptor(queryObj, "query"));
//         } else {
//           throw result.errors[0];
//         }
//       }

//       dispatch({
//         type: SET_VENDOR_UPLOADED_FILES_STATUS,
//         payload: result?.data?.vendorFiles,
//       });
//     } catch (err) {
//       console.log(err.message);
//     }

//     dispatch(unset_loader());
//   };
// }

export function rest_upload_va_file_name(token, type, requestId, file) {
  const whichOneToBeSent =
    type === "XBRL"
      ? { url: file, name: "", type: "" }
      : { name: file?.name, type: "", url: "" };

  console.log(JSON.stringify(whichOneToBeSent));

  fetch(UNIVERSAL.BASEURL + `/vendors/upload/file_request/${requestId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(whichOneToBeSent),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      // dispatch(getPublicReports(token, organization_id));
      // dispatch(set_snack_bar(responseJson.status, responseJson.message));
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      // dispatch(unset_loader());
    });
}

export function rest_upload_va_file_from_ba(
  token,
  isin,
  year,
  file,
  startdate,
  enddate,
  type,
  organization_id,
  source_url,
  user_name,
  requestId,
  isFrom
) {
  var formData = new FormData();
  let data;
  type === "XBRL"
    ? (data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        user_name,
        xbrl_url: file,
        application: "VENDOR",
      }))
    : (data = encrypt({
        "user-token": DEFAULT_VENDOR_USER_TOKEN,
        isin,
        year,
        startdate: startdate + "-01-01",
        enddate: enddate + "-12-31",
        type,
        organization_id,
        source_url,
        user_name,
        application: "VENDOR",
      }));

  // console.log({
  //   token,
  //   isin,
  //   year,
  //   file,
  //   startdate: startdate + "-01-01",
  //   enddate: enddate + "-12-31",
  //   type,
  //   organization_id,
  //   source_url,
  // });

  // console.log(file?.name);

  if (type !== "XBRL") {
    formData.append("data", data);
    formData.append("file", file);
  }

  return async (dispatch) => {
    dispatch(set_loader());

    return fetch(
      UNIVERSAL.ESG_DIAGNOSIS_REST + "/python_apis/create_company_esg_profile",
      type === "XBRL"
        ? {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ data }),
          }
        : {
            method: "POST",
            body: formData,
          }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // dispatch(getPublicReports(token, organization_id));

        if (isFrom === "vendorAdmin" && responseJson?.status) {
          rest_upload_va_file_name(token, type, requestId, file);
        }

        dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function rest_upload_dora_compliance_file(isin, file, token, type) {
  let formData = new FormData();

  formData.append("file", file);

  console.log(isin, file, token, type);

  return async (dispatch) => {
    dispatch(set_loader());
    fetch(UNIVERSAL.BASEURL + `/dora/upload_file/${isin}?type=${type}`, {
      method: "POST",
      body: formData,
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}

export function gql_send_to_magpie(sendToMagpieInput) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        mutation: GQL_SEND_TO_MAGPIE,
        variables: { sendToMagpieInput },
      };

      const result = await client.mutate(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      // dispatch(set_snack_bar(true, "Answers submitted successfully!"));

      // dispatch({
      //   type: SET_LINKAGE_SURVEY_QUESTIONS,
      //   payload: result.data.linkageSurveyQuestions,
      // });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_vendors_by_isin(query) {
  return async (dispatch) => {
    // dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDORS_BY_ISIN,
        variables: { query },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      if (result.data.searchVendors?.length > 0) {
        dispatch({
          type: SET_VENDORS_BY_ISIN,
          payload: result.data.searchVendors,
        });
      } else {
        dispatch({
          type: SET_VENDORS_BY_ISIN,
          payload: [{ isin: query }],
        });
      }
    } catch (err) {
      console.log(err);
    }

    // dispatch(unset_loader());
  };
}

export function gql_get_surveys_by_name(bankId, query) {
  return async (dispatch) => {
    // dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_SURVEYS_BY_NAME,
        variables: { bankId, query },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_SEARCH_SURVEYS_BY_NAME,
        payload: { surveyRes: result.data.searchSurveys, query },
      });
    } catch (err) {
      console.log(err);
    }

    // dispatch(unset_loader());
  };
}

export function gql_get_vendors_by_name(query) {
  return async (dispatch) => {
    // dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDORS_BY_NAME,
        variables: { query, filters: { filterByBank: true }, limit: 8 },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_SEARCH_VENDORS_BY_NAME,
        payload: { vendorRes: result.data.searchVendors, query },
      });
    } catch (err) {
      console.log(err);
    }

    // dispatch(unset_loader());
  };
}

export function gql_get_linkage_surveys() {
  return async (dispatch) => {
    // dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_LINKAGE_SURVEY,
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_LINKAGE_SURVEYS,
        payload: result.data.linkageSurveys,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_linkage_survey_sections(surveyId) {
  return async (dispatch) => {
    // dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_LINKAGE_SURVEY_SECTIONS,
        variables: { surveyId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_LINKAGE_SURVEY_SECTIONS,
        payload: result.data.linkageSurveySections,
      });
    } catch (err) {
      console.log(err);
    }

    // dispatch(unset_loader());
  };
}

export function gql_get_linkage_survey_questions(sectionId, vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_LINKAGE_SURVEY_QUESTIONS,
        variables: { sectionId, vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_LINKAGE_SURVEY_QUESTIONS,
        payload: result.data.linkageSurveyQuestions,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_vendor_materiality_scores(vendorId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDOR_MATERIALITY_SCORE,
        variables: { vendorId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch({
        type: SET_VENDOR_MATERIALITY_SCORES,
        payload: result.data.vendorMaterialityScores,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_submit_linkage_survey_questions(input) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        mutation: GQL_SUBMIT_LINKAGE_QUESTIONS,
        variables: { input },
      };

      const result = await client.mutate(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Answers submitted successfully!"));

      // dispatch({
      //   type: SET_LINKAGE_SURVEY_QUESTIONS,
      //   payload: result.data.linkageSurveyQuestions,
      // });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_search_vendor_iq_companies(params, pagination) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDOR_IQ_COMPANIES,
        variables: { params, pagination },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      if (result.data.searchVendorIQCompanies.data.length === 0) {
        dispatch(set_snack_bar(true, "No result found"));
      }

      dispatch({
        type: SET_VENDOR_IQ_SEARCH_COMPANIES,
        payload: {
          data: result.data.searchVendorIQCompanies.data,
          total: result.data.searchVendorIQCompanies.total,
        },
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_vendor_iq_data(vendoriq_id) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_VENDOR_IQ_DATA,
        variables: { vendoriq_id },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // dispatch(set_snack_bar(true, "Answers submitted successfully!"));

      dispatch({
        type: SET_VENDOR_IQ_DATA,
        payload: result.data.vendorIQData,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_set_vendor_iq_id(vendorId, vendoriq_id) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        mutation: GQL_SET_VENDOR_IQ_ID,
        variables: { vendorId, vendoriq_id },
      };

      const result = await client.mutate(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "Successfully connected to VendorIQ"));

      // dispatch({
      //   type: SET_LINKAGE_SURVEY_QUESTIONS,
      //   payload: result.data.linkageSurveyQuestions,
      // });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_kyp_companies(params) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_KYP_COMPANIES,
        variables: { params },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      if (result.data.kypCompanies.length === 0) {
        dispatch(set_snack_bar(true, "No result found"));
      }

      dispatch({
        type: SET_KYP_COMPANIES,
        payload: result.data.kypCompanies,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_set_kyp_id(vendorId, company) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        mutation: GQL_SET_KYP_ID,
        variables: { vendorId, company },
      };

      const result = await client.mutate(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "mutate"));
        } else {
          throw result.errors[0];
        }
      }

      dispatch(set_snack_bar(true, "KYC done successfully!"));

      // dispatch({
      //   type: SET_LINKAGE_SURVEY_QUESTIONS,
      //   payload: result.data.linkageSurveyQuestions,
      // });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function gql_get_kyp_data(kypId) {
  return async (dispatch) => {
    dispatch(set_loader());

    try {
      const queryObj = {
        query: GQL_GET_KYP_DATA,
        variables: { kypId },
      };

      const result = await client.query(queryObj);

      if (result.errors) {
        const isUnAuth = checkIsUnAuth(result.errors[0]);
        if (isUnAuth) {
          await dispatch(handleUnauthInterceptor(queryObj, "query"));
        } else {
          throw result.errors[0];
        }
      }

      // dispatch(set_snack_bar(true, "Answers submitted successfully!"));

      dispatch({
        type: SET_KYP_DATA,
        payload: result.data.kypData,
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(unset_loader());
  };
}

export function rest_upload_dora_files_from_va(
  token,
  requestId,
  file,
  isin,
  type
) {
  let formData = new FormData();

  formData.append("file", file);

  const fileType =
    type === "Magpie (TSPS Policy)"
      ? "TSPS_Policy"
      : type === "Magpie (Contract)"
      ? "Contract"
      : type === "Magpie (Incident Policy)"
      ? "Incident_Policy"
      : "IS_Policy";

  return async (dispatch) => {
    dispatch(set_loader());
    await fetch(
      UNIVERSAL.BASEURL +
        `/vendors/upload/file_request/dora/${requestId}?isin=${isin}&type=${fileType}`,
      {
        method: "POST",
        headers: {
          // Accept: "application/json",
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        // dispatch(getPublicReports(token, organization_id));
        // dispatch(set_snack_bar(responseJson.status, responseJson.message));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(unset_loader());
      });
  };
}
