import { gql } from "@apollo/client";

export const GET_ALL_VENDORS = gql`
  query VendorsList($pagination: PaginationInput!, $type: String) {
    vendorsList(pagination: $pagination, type: $type) {
      count
      vendors {
        vendoriq_id
        _id
        name
        active
        updatedAt
        address {
          country
          state
        }
        isin
        status
        wazuhGroup
        count
        assignedBy {
          _id
          name
          profileImg {
            name
            type
            url
          }
        }
        assignedOn
      }
    }
  }
`;

// export const GET_VENDOR = gql``;

export const CREATE_VENDOR = gql`
  mutation CreateVendor(
    $name: String!
    $type: String!
    $website: String!
    $isin: String!
    $operation: String
    $employeesCount: String!
    $sector: SectorInput!
    $address: AddressInput!
    $revenue: [RevenueInput!]!
    $turnover: [TurnoverInput!]
  ) {
    createVendor(
      createVendorInput: {
        name: $name
        type: $type
        website: $website
        isin: $isin
        operation: $operation
        employeesCount: $employeesCount
        sector: $sector
        address: $address
        revenue: $revenue
        turnover: $turnover
      }
    ) {
      _id
    }
  }
`;
export const GQL_CREATE_ROI = gql`
  mutation SubmitROI($submitROIDataInput: SubmitROIDataInput!) {
    submitROI(submitROIDataInput: $submitROIDataInput) {
      message
      success
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation UpdateVendor($id: String!, $updateVendorInput: UpdateVendorInput!) {
    updateVendor(id: $id, updateVendorInput: $updateVendorInput) {
      _id
      createdAt
      createdBy
      isin
      employeesCount
      logo {
        name
        type
        url
      }
      name
      updatedAt
      updatedBy
      rtDetails {
        annualExpenseOrEstimatedCostOfTheContractualArrangementForThePastYear
        approvalFromTheManagementBody
        budgetOfTheContractualArrangementForTheUpcomingYear
        contractualArrangementReferenceNumber
        countryOfTheFinancialEntity
        countryOfTheIssuanceOfTheOtherCodeToIdentifyTheICTThirdPartyServiceProvider
        currency
        dateOfLastUpdate
        leiOfTheFinancialEntity
        nameOfTheFinancialEntity
        otherCodeToIdentifyTheICTThirdPartyServiceProvider
        overarchingContractualArrangementReferenceNumber
        typeOfContractualArrangement
        typeOfEntityMaintainingRegisterOfInformation
        typeOfOtherCodeToIdentifyTheICTThirdPartyServiceProvider
      }
    }
  }
`;

export const GET_ALL_STAKEHOLDERS = gql`
  query StakeholderList($vendorId: String!, $type: String) {
    stakeholderList(vendorId: $vendorId, type: $type) {
      _id
      email
      name
      designation
      category
    }
  }
`;

export const ADD_NEW_STAKEHOLDERS = gql`
  mutation AddStakeholder(
    $email: String!
    $name: String!
    $vendorId: ID!
    $category: String!
    $designation: String!
  ) {
    addStakeholder(
      addStakeholderInput: {
        email: $email
        name: $name
        vendorId: $vendorId
        category: $category
        designation: $designation
      }
    ) {
      message
      success
    }
  }
`;

export const GET_VENDOR_BASIC_DETAILS = gql`
  query Vendor($vendorId: String!) {
    vendor(id: $vendorId) {
      _id
      isin
      kypId
      vendoriq_id
      address {
        _id
        country
        district
        landmark
        location
        pincode
        state
      }
      revenue {
        revenue
        currency
        year
        figures
      }
      turnover {
        turnover
        currency
        year
        figures
      }
      sector {
        sector {
          _id
          sector
        }
        industry {
          _id
          industry
        }
      }
      name
      operation
      website
      employeesCount
      logo {
        name
        type
        url
      }
      wazuhGroup
    }
  }
`;

export const REQUEST_FILES_STAKEHOLDERS = gql`
  mutation RequestFiles(
    $stakeholders: [ID!]!
    $vendorId: ID!
    $docs: [VendorDocumentInput!]!
  ) {
    requestFiles(
      requestFilesInput: {
        docs: $docs
        stakeholders: $stakeholders
        vendorId: $vendorId
      }
    ) {
      message
      success
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS_FOR_LANDING_PAGE = gql`
  query Surveys($vendorId: String, $pagination: PaginationInput!) {
    surveys(vendorId: $vendorId, pagination: $pagination) {
      count
      surveys {
        _id
        name
        publishedVersion
        status
        createdOn
        latestVersion
        isShared
        createdBy {
          name
        }
      }
    }
  }
`;

export const GET_BANK_ADMIN_SURVEYS = gql`
  query Surveys($vendorId: String, $type: String) {
    surveys(vendorId: $vendorId, type: $type) {
      count
      surveys {
        _id
        name
        publishedVersion
        type
      }
    }
  }
`;
// export const GET_BANK_ADMIN_SURVEYS = gql`
//   query Surveys($bankId: String!, $version: Float, $vendorId: String) {
//     surveys(bankId: $bankId, vendorId: $vendorId) {
//       _id
//       name
//       publishedVersion
//       status
//       createdOn
//       latestVersion
//       isShared
//       createdBy {
//         name
//       }
//       sections(version: $version) {
//         noOfSections
//         sections {
//           _id
//           active
//           default
//           description
//           isPublished
//           order
//           publishedBy
//           publishedOn
//           title
//           version
//           questions {
//             _id
//             active
//             category
//             categorySuggestion
//             editedAt
//             editedBy
//             order
//             question
//             questionType
//             version
//             weightage
//             answers {
//               option
//               weightage
//             }
//           }
//         }
//       }
//     }
//   }
// `;

export const GET_BANK_ADMIN_SINGLE_SURVEY = gql`
  query Survey($id: String!, $version: Float, $vendorId: String) {
    survey(id: $id, vendorId: $vendorId) {
      _id
      createdOn
      default
      details
      latestVersion
      isShared
      modifiedBy
      modifiedOn
      name
      publishedVersion
      status
      sections(version: $version) {
        noOfSections
        sections {
          _id
          active
          default
          description
          isPublished
          noOfQuestions
          order
          publishedBy
          publishedOn
          title
          version
          questions {
            _id
            active
            category
            categorySuggestion
            editedAt
            editedBy
            order
            question
            questionType
            status
            version
            weightage
            tableHeaders {
              title
              type
            }
            answers {
              option
              weightage
            }
            showComment
          }
        }
      }
    }
  }
`;

export const SHARE_BANK_ADMIN_SURVEY = gql`
  mutation ShareSurvey(
    $stakeholdersIds: [String!]!
    $bankId: String!
    $noOfSurveyReminders: Int!
    $surveyDate: String!
    $surveyId: String!
    $vendorId: String!
    $frequencyTypeName: FrequencyType!
    $version: Int!
    $totalReshares: Int!
  ) {
    shareSurvey(
      shareSurveyInput: {
        stakeholdersIds: $stakeholdersIds
        bankId: $bankId
        noOfSurveyReminders: $noOfSurveyReminders
        surveyDate: $surveyDate
        surveyId: $surveyId
        vendorId: $vendorId
        frequencyTypeName: $frequencyTypeName
        version: $version
        totalReshares: $totalReshares
      }
    ) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_SURVEY = gql`
  mutation UpdateSurvey($updateSurveyInput: UpdateSurveyInput!) {
    updateSurvey(updateSurveyInput: $updateSurveyInput) {
      _id
    }
  }
`;

export const GQL_GET_VA_COMPLETED_SURVEY_FOR_BA = gql`
  mutation GetSharedSurveyVendors($surveyId: String!, $version: Int!) {
    getSharedSurveyVendors(
      getSharedSurveyVendors: { surveyId: $surveyId, version: $version }
    ) {
      _id
      noOfStakeholders
      sharedOn
      vendor {
        name
        _id
      }
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES = gql`
  query VendorFiles($vendorId: ID!) {
    vendorFiles(vendorId: $vendorId) {
      _id
      fileName
      fileType
      fileUrl
      requestId
      type
      uploadedBy {
        _id
        name
      }
      vendorId
      year
      createdAt
      status
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES_STATUS = gql`
  query VendorFiles($vendorId: ID!) {
    vendorFiles(vendorId: $vendorId) {
      _id
      status
    }
  }
`;

export const GET_VENDOR_NAME_FOR_VA = gql`
  query Vendor($vendorId: String!) {
    vendor(id: $vendorId) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
    }
  }
`;

export const GET_SINGLE_SURVEY_QUESTION_COUNT = gql`
  query Survey($id: String!, $version: Float, $vendorId: String) {
    survey(id: $id, vendorId: $vendorId) {
      _id
      sections(version: $version) {
        sections {
          noOfQuestions
        }
      }
    }
  }
`;

export const GQL_GET_VENDOR_UPLOADED_FILES_IN_SURVEY = gql`
  query SurveyDocs($vendorId: String!) {
    surveyDocs(vendorId: $vendorId) {
      uploadedAt
      uploadedBy {
        name
        profileImg {
          name
          type
          url
        }
      }
      document {
        name
        type
        url
      }
    }
  }
`;

export const GQL_GET_VENDORS_BY_ISIN = gql`
  query SearchVendors($query: String!) {
    searchVendors(query: $query) {
      _id
      isin
      name
      logo {
        name
        type
        url
      }
      address {
        _id
        country
        district
        landmark
        location
        phone
        pincode
        state
      }
      employeesCount
      operation
      revenue {
        currency
        figures
        revenue
        year
      }
      sector {
        industry {
          industry
          _id
          industryCode
        }
        sector {
          _id
          sector
          sectorCode
        }
      }
      turnover {
        currency
        figures
        turnover
        year
      }
      type
      website
    }
  }
`;
export const GQL_GET_SURVEYS_BY_NAME = gql`
  query SearchSurveys($bankId: String!, $query: String!) {
    searchSurveys(bankId: $bankId, query: $query) {
      _id
      createdOn
      name
      publishedVersion
      status
      createdBy {
        name
      }
    }
  }
`;

export const GQL_GET_VENDORS_BY_NAME = gql`
  query SearchVendors(
    $query: String!
    $filters: VendorSearchFilter
    $limit: Int
  ) {
    searchVendors(query: $query, filters: $filters, limit: $limit) {
      _id
      active
      name
      updatedAt
      address {
        country
      }
      status
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY = gql`
  query LinkageSurveys {
    linkageSurveys {
      _id
      details
      name
      order
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY_SECTIONS = gql`
  query LinkageSurveySections($surveyId: String!) {
    linkageSurveySections(surveyId: $surveyId) {
      _id
      order
      title
    }
  }
`;

export const GQL_GET_LINKAGE_SURVEY_QUESTIONS = gql`
  query LinkageSurveyQuestions($sectionId: String!, $vendorId: String!) {
    linkageSurveyQuestions(sectionId: $sectionId, vendorId: $vendorId) {
      _id
      answer
      code
      instruction
      linkageAnswer
      linkageId
      mandatory
      name
      order
      type
      weight
      options {
        comment
        description
        documentUpload
        endDate
        justification
        justificationText
        option
        startDate
        weightage
      }
    }
  }
`;

export const GQL_SUBMIT_LINKAGE_QUESTIONS = gql`
  mutation SubmitLinkageAnswer($input: SubmitLinkageAnswerInput!) {
    submitLinkageAnswer(input: $input) {
      message
      success
    }
  }
`;

export const GQL_GET_VENDOR_MATERIALITY_SCORE = gql`
  query VendorMaterialityScores($vendorId: String!) {
    vendorMaterialityScores(vendorId: $vendorId) {
      vendorCategorization {
        comment
        score
        sectionWiseScore
      }
      vendorMateriality {
        comment
        score
        sectionWiseScore
      }
      vendorPerformance {
        comment
        score
        sectionWiseScore
      }
    }
  }
`;

export const GQL_SEND_TO_MAGPIE = gql`
  mutation SendToMagpie($sendToMagpieInput: SendToMagpieInput!) {
    sendToMagpie(sendToMagpieInput: $sendToMagpieInput) {
      message
      success
    }
  }
`;

export const GQL_GET_VENDOR_IQ_COMPANIES = gql`
  query SearchVendorIQCompanies(
    $params: SearchVendorIQCompany!
    $pagination: PaginationInput
  ) {
    searchVendorIQCompanies(params: $params, pagination: $pagination)
  }
`;

export const GQL_GET_VENDOR_IQ_DATA = gql`
  query VendorIQData($vendoriq_id: String!) {
    vendorIQData(vendoriq_id: $vendoriq_id)
  }
`;

export const GQL_SET_VENDOR_IQ_ID = gql`
  mutation SetVendorIQId($vendorId: String!, $vendoriq_id: String!) {
    setVendorIQId(vendorId: $vendorId, vendoriq_id: $vendoriq_id) {
      message
      success
    }
  }
`;

export const GQL_GET_KYP_COMPANIES = gql`
  query KypCompanies($params: KypSearchParams!) {
    kypCompanies(params: $params)
  }
`;

export const GQL_SET_KYP_ID = gql`
  mutation SetKypId($company: SearchKypCompany!, $vendorId: String!) {
    setKypId(company: $company, vendorId: $vendorId) {
      message
      success
    }
  }
`;

export const GQL_GET_KYP_DATA = gql`
  query KypData($kypId: String!) {
    kypData(kypId: $kypId)
  }
`;

export const GQL_DASHBOARD_ALERT = gql`
  query GetAlert($getAlertInput: GetAlertInput!) {
    getAlert(getAlertInput: $getAlertInput) {
      _id
      active
      bankId
      emailsToAlert
      pause

      threshold
      vendorId
      widgetName
    }
  }
`;

export const GQL_CREATE_DASHBOARD_ALERT = gql`
  mutation CreateAlert($createAlertInput: CreateAlertInput!) {
    createAlert(createAlertInput: $createAlertInput) {
      message
      success
    }
  }
`;

export const GQL_UPDATE_DASHBOARD_ALERT = gql`
  mutation UpdateAlert($updateAlertInput: UpdateAlertInput!) {
    updateAlert(updateAlertInput: $updateAlertInput) {
      message
      success
    }
  }
`;
